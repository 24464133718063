
@font-face {
    font-family: "EFCOBrookshireRegular";
    
    src: url('../assets/fontstyles/EFCOBrookshireRegular.ttf') format('truetype');
    
}

@font-face {
   
    font-family: "Giaza";
    
    src: url('../assets/fontstyles/Giaza.otf') format('truetype');
    
}




.Register{
     background-image: url("../assets/event1Bg.png"); 
    background-size: cover;       /* Ensures the background image covers the entire div */
    background-position: center;
    background-attachment: fixed;
    /* Centers the background image */
    height:auto;
    max-width: 100vw;              /* Full viewport height */
    color: white;                 /* Text color to stand out against the background */
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.form
{
    width:80%;
    height:auto;

    border:2px solid #eadecb;

    display:flex;
    flex-direction:column;
    align-items:center;

    margin:5%;
    margin-bottom: 5%;
    background-color: #eadecb;

    padding-bottom:2%;
    /* justify-content: center; */
}

.heading1
{
    width:100%;
    height:80px;

    /* border:2px solid #eadecb; */

    display:flex;
    justify-content: center;
    align-items: center;

    font-family:"Giaza",sans-serif;
    font-size:1.3em;
    background-color: #5f1a1f;
}

.topFrame
{
    width:100%;
    height:auto;
    background-color:#eadecb;

    display:flex;
    flex-wrap: wrap;

    /* align-items:center; */
    justify-content:center;
    margin-bottom:0%;

    padding:3%;
}

.Name
{
    width:50%;
      height: 5%;

    /* border:2px solid black; */
    text-align:center;

    margin-bottom:3%;
}

.Name div{
    font-family:kurale;
    color:black;
    margin-bottom:5px;

    padding-left:10%;
    text-align:left;
}



.Name input{
    width:85%;
    height:40px;

    border-radius:30px;
    background-color: #794937;

    border:none;
    text-align: center;

    color:white;
    font-size: 18px;
    padding-left:10%;
    text-align:left;
}

.Name >  textarea{
    width:85%;
    height:80px;

    border-radius:30px;
    background-color: #794937;

    border:none;
    text-align: center;

    color:white;
    font-size: 18px;
    padding-left:10%;
    text-align:left;

}

#bottom
{
    display:none;
}


#MobQr
{
    display:none;
}

.QrContent{
    display:none;
}

#MobQr img {
    display:none;
  }


#top{
    width: 100%;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.input{
    margin-right: 5%;
}

.participation-list{
padding-bottom: 1%;
color: black;
font-family: "kurale",sans-serif;
}
.checkboxContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:3px dashed #794937;
    font-family: "kurale",sans-serif ;
}
.upper_events{
    padding:1%;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
   
}
.upper_events > label{
    width:100%;
}

.lower_events{
    padding:1%;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    
}
.lower_events > label{
    width:100%;
}

.labell{
    font-family: "Kurale", sans-serif !important;
    color:#794937
}


.deskqr{
    display:block;
    width:180px;
    height:180px;
    background-color:#794937;
    border-radius:20px;
    margin-bottom:5%;

    display:flex;
    align-items:center;
    justify-content:center;
    

}
.deskqr img {
    display:block;
    width:80%;
    height:80%;
  }


    /* Style for the select dropdown */
    .select {
        width:90%;
        height:40px;
    
        border-radius:30px;
        background-color: #794937;
    
        border:none;
        text-align: center;/* Text color */
        font-family:kurale;
        color:white;
        font-size: 18px;
        cursor: pointer;
        transition: all 0.3s ease;
        padding-left:10%;
        text-align:left;
      }
      
      /* Style for options */
      .select option {
        background-color: #905a1e;/* Background color for options */
        color: #333; /* Text color for options */
      }
      
      /* Hover effect */
      .select:hover {
        background-color: #905a1e;/* Background color on hover */
        border-color: #3e8e41; /* Border color on hover */
      }
      
      /* Focus effect */
      .select:focus {
        outline: none;
        border-color: #3e8e41; /* Border color on focus */
        background-color: #905a1e; /* Background color on focus */
      }
      
      /* Disabled option */
      .select option:disabled {
        color: #aaa;
      }
    

   .drama{
margin: 4%;
height: 15%;
   }
.Notee{
  padding-bottom: 3%;
    color: black;
    font-family: 'Kurale';
    font-size: 1.2rem;
}
@media screen and (max-width:623px) {
  .Notee{
    padding-bottom: 5% !important;
      color: black;
      font-family: 'Kurale';
      font-size: 1rem !important;
  }
}

   .submitButton
  {
     width:15%;
     height:40px;

     background-color: #794937;
     color:white;

     font-family:kurale,sans-serif;
     font-size: 1.3em;
     border-radius:30px;

     display:flex;
     align-items:center;
     justify-content:center;
  }


/* Hide the arrow spinners for Chrome, Safari, Edge, and Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the arrow spinners for Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

  /* Media Query */

@media screen and (max-width: 768px) 
{
    .Register
    {
        background-color: #5f1a1f;   
        background-image: none !important; 
    }

    .heading1{
        height:80px;
    }

    .form
    {
        width:85%;
        height:95%;

        margin-top:10%;
        background-color: #eadecb;

        margin-bottom: 150px;

        padding-bottom:30px;
    }

    .topFrame {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom:30px;

        /* position: relative; */
    }

    #bottom
    {
        display: block;
    }

    .Name
    {
        width:95%;
        margin-bottom: 20px; /* Adjusted margin for spacing */
        /* Removed position: absolute; */
        /* Removed top: 10%; */
    }

    .Name div {
        padding-left:7%;
    }

    .Name input
    {
        width:90%;
        font-family:kurale;
        color:white;
        font-size: 18px;
        padding-left:10%;
        text-align:left;
    }



    /* Style for the select dropdown */
   .select {
    width:90%;
    height:40px;

    border-radius:30px;
    background-color: #794937;

    border:none;
    text-align: center;/* Text color */
    font-family:kurale;
    color:white;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding-left:10%;
    text-align:left;
  }
  
  /* Style for options */
  .select option {
    background-color: #905a1e;/* Background color for options */
    color: #333; /* Text color for options */
  }
  
  /* Hover effect */
  .select:hover {
    background-color: #905a1e;/* Background color on hover */
    border-color: #3e8e41; /* Border color on hover */
  }
  
  /* Focus effect */
  .select:focus {
    outline: none;
    border-color: #3e8e41; /* Border color on focus */
    background-color: #905a1e; /* Background color on focus */
  }
  
  /* Disabled option */
  .select option:disabled {
    color: #aaa;
  }

  #MobQr
  {
    display:block;
    width:180px;
    height:180px;
    background-color:#794937;
    border-radius:20px;
    margin-bottom:5%;

    display:flex;
    align-items:center;
    justify-content:center;
  }
 
  
  .QrContent{
    display:block;
    font-family:kurale;
    color:#794937;
  }

  #MobQr img {
    display:block;
    width:80%;
    height:80%;
  }

  #frameBottom
  {
    display:none;
  }

  #top
  {
    display:none;
  }

  .submitButton
  {
     width:40%;
     height:40px;

     background-color: #794937;
     color:white;

     font-family:kurale,sans-serif;
     font-size: 1.3em;
     border-radius:30px;

     display:flex;
     align-items:center;
     justify-content:center;
  }
}
