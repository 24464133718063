.Mobile
{
    background-image:url('../assets/eventBg.png');
    background-size: cover;       /* Ensures the background image covers the entire div */
    background-position: center;
    background-attachment: fixed;
    width:100vw;  /* Centers the background image */
    height: 90vh;
    max-width: 100vw;              /* Full viewport height */
    color: white;                 /* Text color to stand out against the background */
    text-align: center;
   
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}


.event-carousel
{
    width:100%;
    height:90vh;
    border:2px solid black;


    display:flex;
    justify-content:center;
    /* padding:10%; */
    padding-bottom:0%;
    padding-top:5%;
}
        
.event-slide
{
    width:75%;
    height:62vh;
    border: 4px solid #611A22;
    background-color: #EEEEEE;

    display:flex;
    flex-direction:column;
    align-items:center;

    margin-right:5%;
    margin-left:13%;
    border-radius:30px;
}


/* image */

.event-image
{
    width:100%;
    height:45%;

    border-top-left-radius:30px;
    border-top-right-radius:30px;

    padding:7%;
}

.event-image img{
    width:100%;
    height:100%;
}


/* title */
.title
{
    width:100%;
    height:10%;
    background-color:#611A22;

    display:flex;
    justify-content:center;
    align-items:center;

    font-weight: bold;
    font-family: 'Kurale', serif;
    font-size:1.8em;
}

/* Description */

.event-description
{
   

    height: 55%;
    width: 100%;
    padding: 2%;
    display: flex;
    color: black;
    font-size: 1em;
    font-weight: bold;
    overflow: hidden;
    font-family: 'Kurale', serif;
   
    justify-content: center;
}

/* Read More */
.rm
{
    margin-top: 2%;
    height: 9%;
    width: 50%;
    background-color: #611A22;
    border-radius:20px;
    font-family: 'Kurale', serif;

    display:flex;
    justify-content: center;
    align-items: center;

    margin-bottom:3%;
    cursor:pointer;

}






/* button styling */

.carousel .control-dots {
    display: none;
}

.carousel .control-arrow {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: fixed; /* Change to fixed to position relative to viewport */
    bottom: 0px; /* Position at the bottom of the page */
    z-index: 10;
}

.carousel .control-prev.control-arrow {
    left: 0%; /* Adjust this value as needed */
    top:40%;
}

.carousel .control-next.control-arrow {
    right: 0%; /* Adjust this value as needed */
    top:40%;

}
