@font-face {
    font-family: "EFCOBrookshireRegular";
    
    src: url('../assets/fontstyles/EFCOBrookshireRegular.ttf') format('truetype');
    
}

@font-face {
   
    font-family: "Giaza";
    
    src: url('../assets/fontstyles/Giaza.otf') format('truetype');
    
}





.desk{
    background-image: url("../assets/Home-bg-img.jpg");
    background-size: cover;       /* Ensures the background image covers the entire div */
    background-position: center;
    background-attachment: fixed;  /* Centers the background image */
    height: 85vh;
    max-width: 100vw;              /* Full viewport height */
    color: white;                 /* Text color to stand out against the background */
    text-align: center;
   
    display: flex;
    justify-content: center;
    align-items: center;

}

.frame1
{
    width:27%;
    height:90%;
    border:5px solid #5f1a1f;
    border-right-width:3px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;


    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.eventImage
{
    
    width:80%;
    height:80%;
    /* border:2px solid #5f1a1f; */
    display:flex;
    align-items: center;
    justify-content: center;
}

.eventImage img
{
    width:90%;
    height:90%;
}

.eventNamee > h1
{
    color:#5f1a1f;
    font-weight:550;
    font-family: 'EFCOBrookshireRegular', sans-serif;
    font-size: 3.2rem;
}


.frame2
{
    width:55%;
    height:90%;
    border:5px solid #5f1a1f;
    border-left-width:0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

   

    position:relative;
}

.description
{
    width:90%;
    height:auto;
    font-family: "Kurale", serif !important;
    color:#5f1a1f;
    font-size:150%;
    text-align:left;
    font-family: sans-serif;

  

}

.discover
{
    display:inline-flex;
    align-items:center;
    justify-content: center;
    background-color: #5f1a1f;

    font-family:'Giaza',sans-serif;
    font-size:1.5em;
    padding:10px 30px;

    border-radius:40px;
    cursor:pointer;

   
}



@media (max-width: 1024px) {
    .description {
        font-size: 1.2em; /* Reduce font size */
    }
    .discover {
        margin-top: 15%;
    }
    .eventNamee h1{
        font-size:30px;
    }
}

@media (max-width: 768px) {
    .description {
        font-size: 1em; /* Further reduce font size */
    }
    .discover {
        margin-top: 15%;
    }
}

@media (max-width: 480px) {
    .description {
        font-size: 0.8em; /* Even further reduce font size */
    }
    .discover {
        margin-top: 15%;
    }
}