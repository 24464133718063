.buttons{
    width:50%;
    display: flex;
    padding: 1.2%;
    padding-left:15px;
    padding-right: 15px;
 
 
   
    justify-content: center;
    align-items: center;
   
    background-color: rgba(255, 255, 255, 0.562);
    border-radius: 74px;
    position:fixed;
    bottom: 50px;
 
    
 
 
  
 }
 
 a{
    text-decoration: none;
    outline: none !important;
    
 }
 

@media screen and (max-width:900px)
 {
     .buttons{
         width:80%;
         
         position:fixed;
         bottom: 40px;
 
     }
 
     .circle-icon, .calender-icon, .call-icon, .register-icon{
         width: 50px !important;
         height: 50px !important;
     }
     .home{
         background-image: url("../assets/mobile_background.png");
     }
     a {
        outline: none; /* Remove the outline */
    }
    
    a:focus {
        outline: none; /* Ensure no outline when focused */
    }
    
    a:active {
        outline: none; /* Ensure no outline when active (clicked) */
    }
    
    a:focus-visible {
        outline: none; /* For accessibility */
    }
    
    a:focus:not(:focus-visible) {
        outline: none; /* For browsers supporting :focus-visible */
    }
    
    /* Also, you can prevent the default tap highlight color on touch devices */
    a {
        -webkit-tap-highlight-color: transparent;
    }
    
     
 
 }
 .icon-box {
     display: inline-flex; /* Fit the width to content */
     align-items: center; /* Center the icons vertically */
     background-color: #e8ddc0; /* Background color for the icon box *  c9bc9 */
     padding: 10px; /* Padding inside the icon box */
     border-radius: 8px; /* Rounded corners */
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
 }
 
 .home-icon{
     width: 100%;
  
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
  
 }
 .contact-icon{
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
 .register_icon{
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
 .events-icon{
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
 .circle-icon, .calender-icon, .call-icon, .register-icon {
     width: 70px;
     height: 70px;
     border-radius: 50%;
     background-color: #e8ddc093;
     display: flex;
     align-items: center;
     justify-content: center;
     border: 2px solid #5f1a1f;
     
 }
 
 
 .circle-icon i, .calender-icon i, .call-icon i, .register-icon i {
     
     color: #5f1a1f;
 }
 .text-under-icon{
     margin:0px;
     display: none;
 }
 .text-under-icon-show{
     margin:0px;
     display: block;
     color:#5f1a1f;
 }
 @media screen and (min-width:1093px)
 {
    .buttons{
        display: none !important;
     }
 }
 
 
 