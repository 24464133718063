
@font-face {
    font-family: "EFCOBrookshireRegular";

    
    
    src: url('../assets/fontstyles/EFCOBrookshireRegular.ttf') format('truetype');
    
}

@font-face {
   
    font-family: "Giaza";
    
    src: url('../assets/fontstyles/Giaza.otf') format('truetype');
    
}


.home{
    background-image: url("../assets/Home-bg-img.jpg");
    background-size: cover;       /* Ensures the background image covers the entire div */
    background-position: center;  /* Centers the background image */
    height: 90vh;
    max-width: 100vw;              /* Full viewport height */
    color: white;                 /* Text color to stand out against the background */
    text-align: center;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
        
     

}
.presents-quote{
    margin-top:5%;
    font-family: 'EFCOBrookshireRegular', sans-serif;
    
}
.presents-quote > h3{
   font-size: 1.5rem;
   color: #5f1a1f;
}
.presents-quote > p{
    font-family: 'EFCOBrookshireRegular', sans-serif;
    margin-top: 5%;
}
.Brainstrain-name{
    font-size: 1.5rem;
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    font-family: 'EFCOBrookshireRegular', sans-serif;
    letter-spacing: 4px; 
}
.Brainstrain-name > h1{
    font-size: 2.5rem;
    letter-spacing: 4px; 
    text-align: center;
    
    animation: shadowAnimation 3s infinite alternate;

}


/* Define the keyframes for the shadow animation */
@keyframes shadowAnimation {
    0% {
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2),
                     -2px -2px 3px rgba(250, 203, 203, 0.2);
    }
    50% {
        text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3),
                     -4px -4px 6px rgba(0, 0, 0, 0.3);
    }
    100% {
        text-shadow: 6px 6px 9px rgba(0, 0, 0, 0.45),
                     -6px -6px 9px rgba(0, 0, 0, 0.45);
    }
}


@media screen and (min-width:1093px){
    .Brainstrain-name > h1{
        font-size: 7em;
        margin: 0px;
    }
   .Brainstrain-name{
    top:30%;
   }
    .presents-quote > h3{
        font-size: 2.5rem;
    }
    .presents-quote > p{
        font-size: 1.5rem;
    }
    
}
.buttons{
   width:50%;
   display: flex;
   padding: 1.2%;


  
   justify-content: center;
   align-items: center;
  
   background-color: rgba(255, 255, 255, 0.562);
   border-radius: 30px;
   position:absolute;
   bottom: 50px;

   


 
}
@media screen and (max-width:650px)
{
    .buttons{
        width:80%;
        
        position:fixed;
        bottom: 40px;

    }

    .circle-icon, .calender-icon, .call-icon, .register-icon{
        width: 50px !important;
        height: 50px !important;
    }
    .home{
        background-image: url("../assets/mobile_background.png");
    }

}
.icon-box {
    display: inline-flex; /* Fit the width to content */
    align-items: center; /* Center the icons vertically */
    background-color: #e8ddc0; /* Background color for the icon box *  c9bc9 */
    padding: 10px; /* Padding inside the icon box */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.home-icon{
    width: 100%;
 
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
 
}
.contact-icon{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.register_icon{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.events-icon{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.circle-icon, .calender-icon, .call-icon, .register-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #e8ddc093;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #5f1a1f;
    
}


.circle-icon i, .calender-icon i, .call-icon i, .register-icon i {
    
    color: #5f1a1f;
}
.text-under-icon{
    margin:0px;
    display: none;
}
.text-under-icon-show{
    margin:0px;
    display: block;
}

/* about css */
.about{

    display: none;
    max-width: 100vw;
    
}

@media screen and (max-width:940px) {
    .about{
        display: flex;
        max-width: 100vw;
        background-image: url("../assets/about-phone-img.jpg");
        background-size: cover;       /* Ensures the background image covers the entire div */
        background-position: center;
    }
    .about-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0px;
    }
    .team
    {
        height: 80vh !important;
    }
    
    
}
.event-status {
    display: block;
  }
  
  @media (min-width: 1100px) {
    .event-status::before {
      content: "starts on";
    }
    .event-status::after {
      content: "";
    }
  }
  
  @media (max-width: 1100px) {
    .event-status::before {
      content: "starts in";
    }
    .event-status::after {
      content: "";
    }
  }
  
.about-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
.about-header{
    color:#e8ddc0;
    margin: 5%;
    margin-top:25%;
    
    font-family:"EFCOBrookshireRegular",sans-serif; 
}
.about-header > h2{
    padding: 0px;
    margin:0px;
    font-size: 2.5rem;

}
.about-body{
    margin-bottom:10%;
    padding: 3%;
    display: flex;
    width: 80vw;
    height: 50%;
    background-color: #eadece;
    border-radius: 10px;
    text-align: center;
    

}
.about-image{
    width: 100%;
    padding: 2%;
}
.about-image > img{
    width: 100%;
    border-radius:10px ;

}
.about-inner{
    width: 100%;
    padding: 2%;
    width: 100%;
    padding: 2%;
    color: #5f1a2f;
    
}


@media screen and (max-width:940px) {
    .about-D{
        display: none !important;
    }
    .about{
        display: block;
    }
    
}
.about-D{
    
    background-image: url("../assets/about_desktop.png");
    background-size: cover;       /* Ensures the background image covers the entire div */
    background-position: center;  /* Centers the background image */
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-D-image{
    width: 40%;
    height: 100%;
   
}
.about-D-image > img{
   width: 100%;
   height: 100%;
border-radius: 20px;
   
    
}
.about-D > h1{
    font-family:"EFCOBrookshireRegular",sans-serif; 
    padding-bottom: 2%;
}
.about-D-inner{
    width: 60%;
    height: 100%;
    padding:2%;
   
}
.about-D-inner > p {
    display: flex;
    color: #5f1a1f;
    font-size: 1.4rem;
 

font-family:"Kurale", serif;



font-weight: 400;
font-style: normal;
height: 100%;
justify-content: center;
align-items: center;
}

@media screen and (min-width:1200px){
    .about-D-body{
    height:40% !important;
}
.about-D-image{
    width: 30% !important;
}
    
}
.contact{
    background-image: url("../assets/Contact_bg.png");

    background-size: cover;       /* Ensures the background image covers the entire div */
    background-position: center; 
   
        font-family: "Kurale", serif;
        font-weight: 400;
        font-style: normal;
  
      
}
.contact > h1 {
    height:25%;
    display: flex;
  
    align-items: center;



}
.body-contact{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact-details{
    text-align: center;
    width: 100%;
}
.contact-details > div > p{
    font-size: 1.3rem;
    padding: 1%;
    padding-bottom:0px;
    margin: 0px;
}
.contact-last{
    height:5%;
    display: flex;
  
    align-items: center;
    justify-content: center;
}
@media screen and (min-width:1000px) {
   
.contact-last{
    height:25%;
    display: flex;
  
    align-items: center;
    justify-content: center;
}   

}
@media screen and (min-width:1124px){
    
    .datee{
        bottom: 25% !important;
    }
}

/* Icons in Contact */

.icons
{
    width:300px;
    height:100px;

    display:flex;
    align-items:center;
    justify-content:center;

}

.icon1
{
    width:20%;
    height:60%;

    margin:10px;
    border:2px solid white;

    display:flex;
    align-items:center;
    justify-content: center;

    background-color:#e8ddc0;

    border-radius:10px;
    cursor:pointer;
}

.icon2
{
    width:20%;
    height:60%;

    margin:10px;
    border:2px solid white;

    display:flex;
    align-items:center;
    justify-content: center;

    background-color:#e8ddc0;
    border-radius:10px;
    cursor:pointer;
}

.icon3
{
    width:20%;
    height:60%;

    margin:10px;
    border:2px solid white;

    display:flex;
    align-items:center;
    justify-content: center;

    background-color:#e8ddc0;
    border-radius:10px;
    cursor:pointer;
}

.iconImage
{
    width:80%;
    height:80%;
}




