.team-member {
    text-align: center;
    padding: 20px;
    background: #e8ddc0;
   /* border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);*/
  }
  
  .team-member-image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .team-member-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .team-member-role {
    font-size: 1rem;
    color: #555;
  }
  /* Custom styles for the dots */
.custom-dot-list-style .react-multi-carousel-dot button {
  background-color: #5f1a1f; /* Change this to your desired color */
  width: 12px;
  height: 12px;
  border-radius: 50%;
margin-top: 3%;
}

.custom-dot-list-style .react-multi-carousel-dot--active button {
  background-color: #f7b731; /* Change this to your desired active color */
}
.react-multi-carousel-list {
  height: 60vh !important;

}